import {
  SORT_ERROR_LOG,
  SORT_LATEST,
  SORT_OLDEST,
  ALL_CATEGORY,
  DEFAULT_SORT,
  DEFAULT_FILTERS,
  DEFAULT_CONTENT,
  DEFAULT_CATEGORY,
} from '../../constants';

const sortContent = (contents = DEFAULT_CONTENT, sort = DEFAULT_SORT) => {
  switch (sort) {
    case SORT_LATEST:
      return contents;
    case SORT_OLDEST:
      const immutableReversedContents = [...contents].reverse();
      return immutableReversedContents;
    default:
      console.error(SORT_ERROR_LOG);
      return DEFAULT_CONTENT;
  }
};

const removeFirstItem = (array = []) => {
  return array.slice(1);
};

const categorizeContent = (
  contents = DEFAULT_CONTENT,
  category = DEFAULT_CATEGORY
) => {
  const isAllSelected = category === ALL_CATEGORY;
  if (isAllSelected) return removeFirstItem(contents);

  const categorizedContent = contents.filter(
    (content) => content?.category?.name === category
  );

  return categorizedContent;
};

const filterContents = (
  contents = DEFAULT_CONTENT,
  filters = DEFAULT_FILTERS
) => {
  const { sort, category } = filters;
  const categorizedContent = categorizeContent(contents, category);
  const sortedContent = sortContent(categorizedContent, sort);

  return sortedContent;
};

export default filterContents;
