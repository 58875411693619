import React from 'react';
import { useQueryParams } from 'use-query-params';
import CategorySlide from '@components/CategorySlide';
import Topbar from '@components/Topbar';
import ArticleCardsViewer from '@components/ArticleCardsViewer';
import NewsEmptyState from '@modules/News/Viewer/ResultsPreview/NewsEmptyState';
import filterContents from './helpers/methods/filterContents';

import {
  SORT_ERROR_LOG,
  SORT_LATEST,
  SORT_OLDEST,
  DEFAULT_URL_QUERY,
} from './helpers/constants';

const ResultsPreview = ({ contents, categories }) => {
  const [filters, setFilters] = useQueryParams(DEFAULT_URL_QUERY);

  const handleCategoryClick = (category) => () => {
    setFilters({ category });
  };

  const handleSortByChange = (sort) => {
    const isSortByLatest = sort === SORT_LATEST;
    const isSortByOldest = sort === SORT_OLDEST;
    const isValueExpected = isSortByLatest || isSortByOldest;
    if (!isValueExpected) {
      console.error(SORT_ERROR_LOG);
      return;
    }
    setFilters({
      sort,
    });
  };

  const visibleContents = filterContents(contents, filters);

  return (
    <div className="result-preview-container">
      <Topbar
        defaultValue={filters.sort}
        onChange={handleSortByChange}
        count={visibleContents?.length}
      />
      <CategorySlide
        className="result-preview-category"
        categories={categories}
        currentCategory={filters.category}
        onClick={handleCategoryClick}
      />
      <ArticleCardsViewer
        EmptyState={<NewsEmptyState />}
        contents={visibleContents}
        linkRoot={'/news/'}
      />
    </div>
  );
};

export default ResultsPreview;
