import React from 'react';
import Headline from '@components/Headline';
import ResultsPreview from './ResultsPreview';
import Section from '@components/Section';
import EmptyPage from '@components/EmptyState/EmptyPage';
import DataWrapper from '@components/DataWrapper';
import formatPapuaDate from '@helpers/methods/formatZuluTime';

const Viewer = ({ data }) => {
  const {
    latestNewsData,
    moreNewsData,
    allNewsCategories,
    hasNoContent,
  } = data;
  const sampleDate = formatPapuaDate(latestNewsData?.published_date);
  const hasNoSampleDate = !sampleDate;
  const actualDate = formatPapuaDate(latestNewsData?.published_at);
  const newsDate = hasNoSampleDate ? actualDate : sampleDate;
  return (
    <DataWrapper
      isEmpty={hasNoContent}
      empty={
        <EmptyPage
          iconHref={'newspaper'}
          mainMessage={'No available news'}
          subMessage={"We'll let you know when we got news for you."}
        />
      }
    >
      <div className="news-flex-container">
        <Section>
          <Headline
            title={latestNewsData?.title}
            slug={latestNewsData?.slug}
            category={latestNewsData?.category?.name}
            author={latestNewsData?.author?.username}
            date={newsDate}
            content={latestNewsData?.content}
            image={
              latestNewsData?.cover_image?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
            thumbnail={
              latestNewsData?.thumbnail_image?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
          />
        </Section>
        <Section>
          <ResultsPreview
            contents={moreNewsData}
            categories={allNewsCategories}
          />
        </Section>
      </div>
    </DataWrapper>
  );
};

export default Viewer;
