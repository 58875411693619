export const ALL_CATEGORY = 'All';
export const SORT_LATEST = 'Latest';
export const SORT_OLDEST = 'Oldest';
export const SORT_ERROR_LOG = `Sort Value didn't match with "Latest" or "Oldest"`;

export const DEFAULT_SEARCH_RESULT = { results: [], query: '' };
export const DEFAULT_CONTENT = [];
export const DEFAULT_SORT = SORT_LATEST;
export const DEFAULT_CATEGORY = ALL_CATEGORY;

export const DEFAULT_FILTERS = {
  sort: DEFAULT_SORT,
  search: DEFAULT_SEARCH_RESULT,
  category: DEFAULT_CATEGORY,
};

import { StringParam, ArrayParam, withDefault } from 'use-query-params';

export const DEFAULT_URL_QUERY = {
  category: withDefault(StringParam, DEFAULT_CATEGORY),
  sort: withDefault(StringParam, DEFAULT_SORT),
  query: withDefault(StringParam, ''),
  search: withDefault(ArrayParam, []),
};
