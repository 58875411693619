import React from 'react';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

const NewsEmptyState = () => {
  return (
    <div className="article-cards-viewer-empty">
      <div className="empty-search-icon-container">
        <SpriteIconViewer className="empty-search-icon" spriteId="newspaper" />
      </div>
      <h1 className="empty-main-message">No available news</h1>
      <h4 className="empty-sub-message">
        We'll let you know when we got news for you.
      </h4>
    </div>
  );
};

export default NewsEmptyState;
