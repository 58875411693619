import React from 'react';
import Layout from '@components/Layout/LayoutTemplates/JobSeekersLayout';
import SEO from '@components/SEO';
import NewsModule from '@modules/News';

const News = () => (
  <Layout isReleased={true} isMobileComingSoon={false}>
    <SEO title="News" description="News" />
    <div className="news-section">
      <NewsModule />
    </div>
  </Layout>
);

export default News;
