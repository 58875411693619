import { ALL_CATEGORY, DEFAULT_ID } from '@modules/News/helpers/constants';
import { useStaticQuery, graphql } from 'gatsby';
import isEmpty from 'lodash/isEmpty';
import sortByPublishedDate from '@helpers/methods/sortByPublishedDate';
import getNewsCardsProps from '@helpers/methods/modifyContents/getNewsCardsProps';

const DEFAULT_CATEGORY = {
  strapiId: DEFAULT_ID,
  name: ALL_CATEGORY,
};

const Fetcher = ({ children }) => {
  const {
    latestStrapiNewsArticle,
    moreStrapiNewsArticles,
    allStrapiNewsCategories,
  } = useStaticQuery(
    graphql`
      {
        latestStrapiNewsArticle: allStrapiNewsArticles(
          limit: 1
          sort: { order: DESC, fields: published_date }
        ) {
          nodes {
            creator_id
            author: created_by {
              username
            }
            category: news_category {
              name
            }

            cover_image {
              source_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                  }
                }
              }
              cropped_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                  }
                }
              }
            }
            cover_image_old: image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }

            thumbnail_image {
              source_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
              }
              cropped_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
              }
            }
            thumbnail_image_old: image_thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 100)
                }
              }
            }

            title
            slug
            content
            strapiId
            created_at(formatString: "MMMM DD, YYYY")
            published_date
            published_at
          }
        }
        moreStrapiNewsArticles: allStrapiNewsArticles(
          sort: { order: DESC, fields: published_date }
        ) {
          nodes {
            creator_id
            author: created_by {
              username
            }
            slug
            category: news_category {
              name
            }

            thumbnail_image {
              source_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
              }
              cropped_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
              }
            }
            thumbnail_image_old: image_thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 100)
                }
              }
            }

            title
            content
            strapiId
            created_at(formatString: "MMMM DD, YYYY")
            published_date
            published_at
          }
        }
        allStrapiNewsCategories(sort: { fields: sort, order: ASC }) {
          nodes {
            strapiId
            name
          }
        }
      }
    `
  );

  const moreNewsData = getNewsCardsProps(moreStrapiNewsArticles?.nodes);

  let latestNewsData = getNewsCardsProps(latestStrapiNewsArticle?.nodes);
  latestNewsData = latestNewsData?.[0];

  const allNewsCategories = [
    DEFAULT_CATEGORY,
    ...allStrapiNewsCategories.nodes,
  ];

  let hasNoContent = isEmpty(latestStrapiNewsArticle?.nodes);

  return children({
    latestNewsData,
    moreNewsData,
    allNewsCategories,
    hasNoContent,
  });
};

export default Fetcher;
