import React from 'react';

import Fetcher from './Fetcher';
import Viewer from './Viewer';

const News = () => {
  return <Fetcher>{(data) => <Viewer data={data} />}</Fetcher>;
};

export default News;
